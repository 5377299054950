.phraseIntro{
    font-size:x-large;
    font-style: italic;
    text-align: center;
    width: fit-content;
    margin: 0 auto 0 auto;
}

.latest{
    padding-left: 0.5%;
    font-size: large;
    color: rgba(244, 243, 232);
}

.vignettes{
    background-color:rgba(214, 195, 178);
    margin: 10% 0 0 0;
    padding: 0 0 2% 0;
    min-width: 100%;
    height: auto;
}
.reviewsContainer
{
    margin: auto;
    width: 85%;
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: space-between;
    
}
.flip-card{
    margin: auto; 
 }
 .logoAccueil{
     margin-top: 2%;
     padding-bottom: 2%;
 }
 .logoImg{
    display: block;
    margin: auto;
     height: auto;
     width: 17%;
 }
@media only screen and (max-width: 2000px) {
    .vignettes
    {
        width: 1024px;
    }
}
@media only screen and (max-width: 1024px) {
    .vignettes
    {
        width: 768px;
    }
    .phraseIntro{
        font-size:larger;
    }
}
@media only screen and (max-width: 768px) {
    .vignettes
    {
        margin-top: 20%;
        width: 425px;
    }
    .phraseIntro{
        font-size:medium;
    }
}
@media only screen and (max-width: 425px) {
    .vignettes
    {
        width: 375px;
    }
    .reviewsContainer
    {
        grid-template-columns: 100%;
    }
    .flip-card{
        margin-top: 10%;
    }
    .phraseIntro{
        font-size:smaller;
        word-wrap: break-word;
        width: 100%;
        margin-top: 5%;
        padding: 0 12% 0 12%;
    }
    .logoAccueil{
        margin-top: 25%;
    }
    .logoImg{
        width: 40%;
     }
}
@media only screen and (max-width: 424px) {
    .vignettes
    {
        width: 320px;
    }
    .phraseIntro{
        padding: 0 15% 0 15%;
    }
}
@media only screen and (max-width: 375px) {
    .vignettes
    {
        width: 320px;
    }
    .phraseIntro{
        font-size:small;
        padding: 0 5% 0 10%;
    }
}
@media only screen and (max-width: 320px) {
    .vignettes
    {
        width: 80%;
    }
}
