.facebookShare{
    width: 5%;
    margin: 0;
}
.twitterShare{
    width: 5%;
    margin: 0;
}
@media only screen and (max-width:1440px){
    .facebookShare{
        width: 7%;
    }
    .twitterShare{
        width: 7%;
    }
}
@media only screen and (max-width: 1024px) {
    .facebookShare{
        width: 10%;
    }
    .twitterShare{
        width: 10%;
    }
}
@media only screen and (max-width: 768px) {
    .facebookShare{
        width: 12%;
    }
    .twitterShare{
        width: 12%;
    }
}
@media only screen and (max-width: 425px) {
    .facebookShare{
        width: 20%;
    }
    .twitterShare{
        width: 20%;
    }
}
@media only screen and (max-width: 375px) {
    .facebookShare{
        width: 25%;
    }
    .twitterShare{
        width: 25%;
    }
}