.archives{
    margin-top: 2%;
}
.archivesContainer
{
    width: 1200px;
    display: grid;
    grid-template-columns: 25% 25% 25%;
    margin: auto;
    justify-content: space-between;   
}
.reviewArchive{
    margin-bottom: 10%;
    justify-self: center;
} 
.seeMore{
	width: 25%;
	border: none;
    cursor: pointer;
	font-size: 20px;
	margin: 5% auto 0 38%;
    color: black;
}
.seeMore:hover{
	background-color: #e7e7e7;
}
@media only screen and (min-width: 2559px) {
    .archivesContainer
    {
        width: 2000px;
    }
    .seeMore{
        font-size: 30px;
    }
}
@media only screen and (max-width: 2000px) {
    .archivesContainer
    {
        width: 1024px;
    }
}
@media only screen and (max-width: 1024px) {
    .archivesContainer
    {
        width: 1024px;
        grid-template-columns: 30% 30% 30%;
    }
    .seeMore{
        margin: 5% auto 0 40%;
    }
}
@media only screen and (max-width: 768px) {
    .archivesContainer
    {
        width: 768px;
        grid-template-columns: 50% 50%;
    }
}
@media only screen and (max-width: 425px) {
    .archives{
        margin-top: 20%;
    }
    .archivesContainer
    {
        width: 425px;
        grid-template-columns: 100%;
    }
    .seeMore{
        width: 100%;
        margin-left: 5%;
    }
}
@media only screen and (max-width: 424px) {
    .archivesContainer
    {
        width: 375px;
    }
}
@media only screen and (max-width: 375px) {
    .archivesContainer
    {
        width: 320px;
    }
}
@media only screen and (max-width: 320px) {
    .archivesContainer
    {
        width: 80%;
    }
}
