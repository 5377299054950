.containerRedaction{
    margin-top: 2%;
}
.redactionDiv{
    width: 75%;
    margin-left: 12%;
    padding-bottom: 10px;
    display: grid;
}
.redactionDiv input{
    width: 20%;
    
}
.redactionDiv #phraseDeb{
    width: 100%;
}
.redactionDiv button{
    margin-left: 40%;
    
}
.editor ReviewEditor{
    height: 100px;
}
.dialog .grid-container{
    display: grid;
    grid-template-columns: 100px auto;
}
.dialog .grid-container svg{
    margin-top: 2%;
}
.questionDiv{
    width: 75%;
    margin-left: 12%;
    padding-bottom: 10px;
    display: grid;
}
.questionDiv button{
    width: fit-content;
    margin: auto;
}
.questionInput{
    width: 90%;
}