.aboutClass{
    margin: 2% 0 3% 5%;
    font-size: 50px;
    font-style: italic;
    cursor: default;
}
.textIntro{
    width: 50%;
    margin: 0 auto 0 auto;
    display: grid;
    grid-template-columns: 50% 50%;
}

.popcorn{
    min-width: 80%;
    max-width: 80%;
    height: auto;
}

.textIntro p{
    text-align: justify;
    cursor: default;
}

.textIntro p span{
    font-size: 15px; 
    font-style: normal; 
    font-variant-ligatures: normal; 
    font-variant-caps: normal; 
    font-weight: 400; 
    letter-spacing: normal; 
    orphans: 2; 
    text-align: left; 
    text-indent: 0px; 
    text-transform: none; 
    white-space: pre-wrap; 
    widows: 2; 
    word-spacing: 0px; 
    -webkit-text-stroke-width: 0px; 
    text-decoration-thickness: initial; 
    text-decoration-style: initial; 
    text-decoration-color: initial; 
    display: inline; 
    float: none;
    cursor: default;
}

.bioContainer{
    background-color:rgba(214, 195, 178);
    padding-bottom: 3%;
    margin: 0 auto 0 auto;
}
.bioTitre{
    margin: 2% 0 3% 5%;
    padding-top: 2%;
    font-size: 50px;
    font-style: italic;
    cursor: default;
}
.bio{
    width: 50%;
    margin: 0 auto 0 auto;
    display: grid;
    grid-template-columns: 50% 50%;
}
.bio p{
    text-align: justify;
    cursor: default;
}

.bio p span{
    font-size: 15px; 
    font-style: normal; 
    font-variant-ligatures: normal; 
    font-variant-caps: normal; 
    font-weight: 400; 
    letter-spacing: normal; 
    orphans: 2; 
    text-align: left; 
    text-indent: 0px; 
    text-transform: none; 
    white-space: pre-wrap; 
    widows: 2; 
    word-spacing: 0px; 
    -webkit-text-stroke-width: 0px; 
    text-decoration-thickness: initial; 
    text-decoration-style: initial; 
    text-decoration-color: initial; 
    display: inline; 
    float: none;
}
.bioImg{
    min-width: 80%;
    max-width: 80%;
    height: auto;
    margin-left: auto;
}

.thanks{
    margin: 2% auto 3% auto;
    min-height: 400px;
}
.thanksContainer{
    width: 50%;
    margin: 0 auto 0 auto;
    text-align: left;
    display: grid;
    grid-template-columns: 60%;
}
.thanks .thanksTxt{
    font-size: 20px;
    display: block;
    cursor: default;
}
.thanks .thanksSTxt{
    cursor: default;
}
.thanksTxtDiv{
    margin-bottom: 3%;
}
.thanks .thanksTitre{
    margin: 2% 0 3% 5%;
    font-size: 50px;
    font-style: italic;
    cursor: default;
}

@media only screen and (max-width: 1440px) {
    .textIntro{
        width: 70%;
    }
    .bio{
        width: 70%;
    }
    .thanksContainer{
        width: 70%;
    }
}
@media only screen and (max-width: 1024px) {
    .textIntro{
        width: 90%;
    }
    .bio{
        width: 90%;
    }
    .thanksContainer{
        width: 90%;
    }
}
@media only screen and (max-width: 768px) {
    .textIntro{
        width: 100%;
        grid-template-columns: 40% 60%;
    }
    .textIntro p span{
        font-size: small;
    }
    .popcorn{
        min-width: 90%;
        max-width: 90%;
    }
    .bio{
        width: 100%;
        grid-template-columns: 50% 50%;
    }
    .bioImg{
        min-width: 80%;
        max-width: 80%;
    }
    .bio p span{
        font-size: small;
    }
    .thanksContainer{
        width: 100%;
        grid-template-columns: 60%;
    }
    .thanks{
        font-size: small;
    }
}
@media only screen and (max-width: 425px) {
    .textIntro{
        width: 100%;
        grid-template-columns: 100%;
    }
    .textIntro p{
        margin-top: 5%;
    }
    .textIntro p span{
        font-size: small;
    }
    .popcorn{
        min-width: 100%;
        max-width: 100%;
    }
    .bioContainer{
        padding-bottom: 10%;
    }
    .bio{
        width: 100%;
        grid-template-columns: 100%;
    }
    .bioImg{
        min-width: 100%;
        max-width: 100%;
    }
    .thanksContainer{
        width: 100%;
        grid-template-columns: 100%;
    }
}
@media only screen and (max-width: 375px) {
    .thanks{
        min-height: 300px;
    }
    .thanks .thanksTitre{
        font-size: 40px;
        margin: 2% 0 3% 5%;
    }
    .thanks .thanksTxt{
        font-size: medium;
    }
    .bioTitre{
        font-size: 40px;
    }
    .aboutClass{
        font-size: 40px;
    }
}
@media only screen and (max-width: 320px) {
    .thanks .thanksTitre{
        font-size: 35px;
    }
    .thanks .thanksTxt{
        font-size: small;
    }
    .thanks .thanksSTxt{
        font-size: smaller;
    }
    .bioTitre{
        font-size: 35px;
    }
    .aboutClass{
        font-size: 35px;
    }
}
