img{
    width:300px;
    height:400px;
}
.flip-card {
    background-color: white;
    width: 300px;
    height: 400px;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
    cursor:pointer;
  }
  
  /* This container is needed to position the front and back side */
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  .flip-card:hover .flip-card-front {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0.3;
    transition: .5s ease;
    background-color: #ffffff;
  }
  
  /* Position the front and back side */
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  .flip-card-back {
    opacity: 0;
  }
  .flip-card-back p {
    text-align: center;
    margin : 50% 5% 0 5%;
    font-weight: bold;
  }
  .flip-card:hover .flip-card-back {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
    transition: .5s ease;
    background-color: transparent;
  }

  @media only screen and (min-width: 2559px) {
    .flip-card {
        width: 450px;
        height: 550px;
      }
    img{
      width:450px;
      height:550px;
    }
}

@media only screen and (max-width: 1199px) {
    .flip-card {
        background-color: transparent;
        width: 250px;
        height: 350px;
        border: 1px solid #f1f1f1;
      }
    img{
      width:250px;
      height:350px;
    }
}
@media only screen and (max-width: 1023px) {
  .flip-card {
      width: 200px;
      height: 300px;
    }
    img{
      width:200px;
      height:300px;
    }
}
@media only screen and (max-width: 767px) {
    .flip-card{
       margin: 0 0 5% 0;   
    }
}