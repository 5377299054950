.field-set{
    width: 100%;
}
.form {
    width: 450px;
    min-height: 500px;
    height: auto;
    border-radius: 5px;
    margin: 5% auto;
    box-shadow: 0 9px 50px hsla(20, 67%, 75%, 0.31);
    padding: 2%;
    background: white;
}
/* form Container */
.form .con {
    display: -webkit-flex;
    display: flex;
  
    -webkit-justify-content: space-around;
    justify-content: space-around;
  
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  
      margin: 0 auto;
}
/* inputs form  */
.form-input{
    width: 100%;
    height: 50px;
  
    margin-top: 2%;
    padding: 15px;
    
    font-size: 16px;
    font-family: 'Abel', sans-serif;
  
    outline: none;
    border: none;
  
    border-radius: 0px 5px 5px 0px;

    border-style: solid;
    border-color:rgba(138, 75, 42, .30);
    
}
.form-input-pwd{
    padding: 15px;
    width: 95%;
    height: 100%;
    
    font-size: 16px;
    font-family: 'Abel', sans-serif;
  
    outline: none;
    border: none;
  
    background-color: transparent;
    
}

.inputDiv{
    
    margin-top: 2%;

    width: 100%;
    height: 51px;

    border-radius: 0px 5px 5px 0px;

    border-style: solid;
    border-color:rgba(138, 75, 42, .30);
}

button {
    display: inline-block;
    color: #252537;
  
    width: 280px;
    height: 50px;
  
    padding: 0 20px;
    background-color:white;
    border-radius: 5px;
    
    outline: none;
    border: none;
  
    cursor: pointer;
    text-align: center;
    transition: all 0.2s linear;
    
    margin: 7% 0 0 12%;
    letter-spacing: 0.05em;
}

/* buttons hover */
button:hover {
    transform: translatey(3px);
    box-shadow: none;
}

/* buttons hover Animation */
button:hover {
    animation: ani9 0.4s ease-in-out infinite alternate;
}
@keyframes ani9 {
    0% {
        transform: translateY(3px);
    }
    100% {
        transform: translateY(5px);
    }
}
/* Show/hide password Font Icon */
#eye {
    background: #fff;
    color: #333;
  
    margin: 5.9px 0 0 0;
    margin-left: -20px;
    padding: 15px 9px 19px 0px;
    border-radius: 0px 5px 5px 0px;
  
    float: right;
    position: relative;
    right: 1%;
    top: -.2%;
    z-index: 5;
    
    cursor: pointer;
}
