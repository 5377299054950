@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.navbar-brand{
  width: 30%;
}
.webName{
  height: auto;
  min-width: 50%;
  max-width: 50%;
}
.navbar{
  position:fixed;
  background-color:rgba(214, 195, 178);
  width: 100%;
  z-index: 5000;
}
header{
  padding-bottom: 100px;
}
.mediaNav
{
  min-width: 10%;
  max-width: 10%;
  margin-right: 3%;
  height: auto;
  float: left;
}
.navContainer
{
  min-width: 100%;
  margin: 0 0 0 0 ;
}

html {
  font-size: 10px;
}
@media (min-width: 769px) {
  html {
    font-size: 14px;
  }
}
@media (min-width: 1025px) {
  html {
    font-size: 16px;
  }
}
@media only screen and (min-width: 2560px) {
  html {
    font-size: 25px;
  }
}
@media (max-width:1024px){
  .webName{
    min-width: 60%;
    max-width: 60%;
  }
}
@media (max-width:768px){
  .webName{
    min-width: 80%;
    max-width: 80%;
  }
}
@media (max-width: 426px) {
  html {
    font-size: 16px;
  }
  .navbar-brand{
    width: 70%;
  }
  .webName{
    min-width: 70%;
    max-width: 70%;
    float: left;
  }
  .mediaContainer{
    width: 15%;
  }
  .mediaNav
  {
    margin-left: 5%;
    margin-right: 0;
    min-width: 40%;
    max-width: 40%;
  }
  .navContainer{
    display: grid;
  }
  .mr-2{
    width: 50%;
    height: auto;
    margin: 0 0 0 25%;
  }
}
/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
body{
  background-color:white;
  font-family: 'Playfair Display', serif;
}
.mainContainer{
  min-width: 100%;
  margin: 0 0 0 0;
}
.construction{
    width: 50%;
    margin: 15% auto 0 auto;
    text-align: center;
    display: grid;
    grid-template-columns: 100%;
}
.construction span{
  font-size: 40px;
  display: block;
  cursor: default;
}
@media only screen and (max-width: 1024px) {
  .construction{
    margin: 25% auto 0 auto;
  }
  .construction span{
    font-size: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .construction{
    margin: 40% auto 0 auto;
  }
  .construction span{
    font-size: 23px;
  }
}
@media only screen and (max-width: 425px) {
  .construction{
    margin: 60% auto 0 auto;
  }
  .construction span{
    font-size: 21px;
  }
}
@media only screen and (max-width: 375px) {
  .construction{
    margin: 70% auto 0 auto;
  }
  .construction span{
    font-size: 18px;
  }
}
@media only screen and (max-width: 320px) {
  .construction{
    margin: 90% auto 0 auto;
  }
  .construction span{
    font-size: 16px;
  }
}
.field-set{
    width: 100%;
}
.form {
    width: 450px;
    min-height: 500px;
    height: auto;
    border-radius: 5px;
    margin: 5% auto;
    box-shadow: 0 9px 50px hsla(20, 67%, 75%, 0.31);
    padding: 2%;
    background: white;
}
/* form Container */
.form .con {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  
      margin: 0 auto;
}
/* inputs form  */
.form-input{
    width: 100%;
    height: 50px;
  
    margin-top: 2%;
    padding: 15px;
    
    font-size: 16px;
    font-family: 'Abel', sans-serif;
  
    outline: none;
    border: none;
  
    border-radius: 0px 5px 5px 0px;

    border-style: solid;
    border-color:rgba(138, 75, 42, .30);
    
}
.form-input-pwd{
    padding: 15px;
    width: 95%;
    height: 100%;
    
    font-size: 16px;
    font-family: 'Abel', sans-serif;
  
    outline: none;
    border: none;
  
    background-color: transparent;
    
}

.inputDiv{
    
    margin-top: 2%;

    width: 100%;
    height: 51px;

    border-radius: 0px 5px 5px 0px;

    border-style: solid;
    border-color:rgba(138, 75, 42, .30);
}

button {
    display: inline-block;
    color: #252537;
  
    width: 280px;
    height: 50px;
  
    padding: 0 20px;
    background-color:white;
    border-radius: 5px;
    
    outline: none;
    border: none;
  
    cursor: pointer;
    text-align: center;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    
    margin: 7% 0 0 12%;
    letter-spacing: 0.05em;
}

/* buttons hover */
button:hover {
    -webkit-transform: translatey(3px);
            transform: translatey(3px);
    box-shadow: none;
}

/* buttons hover Animation */
button:hover {
    -webkit-animation: ani9 0.4s ease-in-out infinite alternate;
            animation: ani9 0.4s ease-in-out infinite alternate;
}
@-webkit-keyframes ani9 {
    0% {
        -webkit-transform: translateY(3px);
                transform: translateY(3px);
    }
    100% {
        -webkit-transform: translateY(5px);
                transform: translateY(5px);
    }
}
@keyframes ani9 {
    0% {
        -webkit-transform: translateY(3px);
                transform: translateY(3px);
    }
    100% {
        -webkit-transform: translateY(5px);
                transform: translateY(5px);
    }
}
/* Show/hide password Font Icon */
#eye {
    background: #fff;
    color: #333;
  
    margin: 5.9px 0 0 0;
    margin-left: -20px;
    padding: 15px 9px 19px 0px;
    border-radius: 0px 5px 5px 0px;
  
    float: right;
    position: relative;
    right: 1%;
    top: -.2%;
    z-index: 5;
    
    cursor: pointer;
}

.overlay {
    width: 50%;
    min-height: 500px;
    height: auto;
    border-radius: 5px;
    margin: 2% auto;
    box-shadow: 0 9px 50px hsla(20, 67%, 75%, 0.31);
    padding: 2%;
    background: white;
}
.logo{
    margin-top: 5%;
    text-align: center;
}

.logo img{
    height: auto;
    min-width: 20%;
    max-width: 20%;
    opacity: 0.8;
}
.title{
    font-size:300%;
    font-weight: bold;
    text-align: center;
    display: block;
}
.sousTitre{
    font-size:large;
    font-weight: bold;
    text-align: center;
    display: block;
    margin-bottom: 2%;
}
.realisateur{
    text-align: center;
    font-size:small;
}
.review{
    font-size:medium;
    margin: 5% 10% 0 10%;
    text-align: justify;
    cursor:default;
}
.datePub{
    width: 100%;
    margin: 2% 0 0 0;
    text-align: right;
}
.socialMediaButtons{
    width: 35%;
    margin: 2% auto 0 auto;
    display: grid;
    grid-template-columns: 50% 50%;
}
.facebookWrapper{
    width: 50%;
    text-align: center;
    display: grid;
    padding-left: 85%;
    grid-template-rows: 50% 50%;
}
    
.twitterWrapper{
    width: 50%;
    padding-right: 40%;
    text-align: center;
}
.questionsList{
    margin: 7% auto;
}
.questionsList .questionTitre{
    font-size:larger;
    font-weight: bold;
    text-align: center;
    display: block;
    margin-bottom: 5%;
}
.questionsList .questionDiv{
    width: 80%;
    font-size:medium;
    margin: auto;
    text-align: justify;
    cursor:default;
}
@media only screen and (max-width: 1440px) {
    .facebookWrapper{
        padding-left: 80%;
    }
}
@media only screen and (max-width: 1024px) {
    .overlay
    {
        width: 75%;
    }
    .socialMediaButtons{
        width: 50%;
    }
    .facebookWrapper{
        padding-left: 80%;
    }
        
    .twitterWrapper{
        padding-right: 40%;
    }
}
@media only screen and (max-width: 768px) {
    .overlay
    {
        width: 80%;
    }
    .datePub{
        font-size: medium;
    }
    .questionsList .questionTitre{
        font-size: large;
    }
    .socialMediaButtons{
        width: 70%;
    }
    .facebookWrapper{
        padding-left: 80%;
    }
        
    .twitterWrapper{
        padding-right: 40%;
    }
}
@media only screen and (max-width: 425px) {
    .overlay
    {
        margin-top: 25%;
        width: 95%;
    }
    .review{
        text-justify:inter-character;
        font-size: smaller;
    }
    .logo img{
        min-width: 30%;
        max-width: 30%;
    }
    .title{
        font-size:275%;
    }
    .realisateur{
        font-size:x-small;
    }
    .datePub{
        font-size: smaller;
    }
    .questionsList .questionTitre{
        margin-top:10%;
    }
    .questionsList .questionDiv{
        font-size: smaller;
    }
    .socialMediaButtons{
        width: 100%;
    }
    .facebookWrapper{
        padding-left: 80%;
    }
        
    .twitterWrapper{
        padding-right: 40%;
    }
}
@media only screen and (max-width: 375px) {
    .review{
        font-size: x-small;
    }
    .title{
        font-size:250%;
    }
    .datePub{
        font-size: x-small;
    }
    .questionsList .questionDiv{
        font-size: x-small;
    }
    .facebookWrapper{
        padding-left: 70%;
    }
        
    .twitterWrapper{
        padding-right: 35%;
    }
}
.facebookShare{
    width: 5%;
    margin: 0;
}
.twitterShare{
    width: 5%;
    margin: 0;
}
@media only screen and (max-width:1440px){
    .facebookShare{
        width: 7%;
    }
    .twitterShare{
        width: 7%;
    }
}
@media only screen and (max-width: 1024px) {
    .facebookShare{
        width: 10%;
    }
    .twitterShare{
        width: 10%;
    }
}
@media only screen and (max-width: 768px) {
    .facebookShare{
        width: 12%;
    }
    .twitterShare{
        width: 12%;
    }
}
@media only screen and (max-width: 425px) {
    .facebookShare{
        width: 20%;
    }
    .twitterShare{
        width: 20%;
    }
}
@media only screen and (max-width: 375px) {
    .facebookShare{
        width: 25%;
    }
    .twitterShare{
        width: 25%;
    }
}
.archives{
    margin-top: 2%;
}
.archivesContainer
{
    width: 1200px;
    display: grid;
    grid-template-columns: 25% 25% 25%;
    margin: auto;
    justify-content: space-between;   
}
.reviewArchive{
    margin-bottom: 10%;
    justify-self: center;
} 
.seeMore{
	width: 25%;
	border: none;
    cursor: pointer;
	font-size: 20px;
	margin: 5% auto 0 38%;
    color: black;
}
.seeMore:hover{
	background-color: #e7e7e7;
}
@media only screen and (min-width: 2559px) {
    .archivesContainer
    {
        width: 2000px;
    }
    .seeMore{
        font-size: 30px;
    }
}
@media only screen and (max-width: 2000px) {
    .archivesContainer
    {
        width: 1024px;
    }
}
@media only screen and (max-width: 1024px) {
    .archivesContainer
    {
        width: 1024px;
        grid-template-columns: 30% 30% 30%;
    }
    .seeMore{
        margin: 5% auto 0 40%;
    }
}
@media only screen and (max-width: 768px) {
    .archivesContainer
    {
        width: 768px;
        grid-template-columns: 50% 50%;
    }
}
@media only screen and (max-width: 425px) {
    .archives{
        margin-top: 20%;
    }
    .archivesContainer
    {
        width: 425px;
        grid-template-columns: 100%;
    }
    .seeMore{
        width: 100%;
        margin-left: 5%;
    }
}
@media only screen and (max-width: 424px) {
    .archivesContainer
    {
        width: 375px;
    }
}
@media only screen and (max-width: 375px) {
    .archivesContainer
    {
        width: 320px;
    }
}
@media only screen and (max-width: 320px) {
    .archivesContainer
    {
        width: 80%;
    }
}

img{
    width:300px;
    height:400px;
}
.flip-card {
    background-color: white;
    width: 300px;
    height: 400px;
    -webkit-perspective: 1000px;
            perspective: 1000px; /* Remove this if you don't want the 3D effect */
    cursor:pointer;
  }
  
  /* This container is needed to position the front and back side */
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    -webkit-transition: -webkit-transform 0.8s;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
  }
  
  .flip-card:hover .flip-card-front {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0.3;
    -webkit-transition: .5s ease;
    transition: .5s ease;
    background-color: #ffffff;
  }
  
  /* Position the front and back side */
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  .flip-card-back {
    opacity: 0;
  }
  .flip-card-back p {
    text-align: center;
    margin : 50% 5% 0 5%;
    font-weight: bold;
  }
  .flip-card:hover .flip-card-back {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
    -webkit-transition: .5s ease;
    transition: .5s ease;
    background-color: transparent;
  }

  @media only screen and (min-width: 2559px) {
    .flip-card {
        width: 450px;
        height: 550px;
      }
    img{
      width:450px;
      height:550px;
    }
}

@media only screen and (max-width: 1199px) {
    .flip-card {
        background-color: transparent;
        width: 250px;
        height: 350px;
        border: 1px solid #f1f1f1;
      }
    img{
      width:250px;
      height:350px;
    }
}
@media only screen and (max-width: 1023px) {
  .flip-card {
      width: 200px;
      height: 300px;
    }
    img{
      width:200px;
      height:300px;
    }
}
@media only screen and (max-width: 767px) {
    .flip-card{
       margin: 0 0 5% 0;   
    }
}
.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: 'Georgia', serif;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 500px;
  max-height: 500px;
  overflow-x: scroll;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}
.containerRedaction{
    margin-top: 2%;
}
.redactionDiv{
    width: 75%;
    margin-left: 12%;
    padding-bottom: 10px;
    display: grid;
}
.redactionDiv input{
    width: 20%;
    
}
.redactionDiv #phraseDeb{
    width: 100%;
}
.redactionDiv button{
    margin-left: 40%;
    
}
.editor ReviewEditor{
    height: 100px;
}
.dialog .grid-container{
    display: grid;
    grid-template-columns: 100px auto;
}
.dialog .grid-container svg{
    margin-top: 2%;
}
.questionDiv{
    width: 75%;
    margin-left: 12%;
    padding-bottom: 10px;
    display: grid;
}
.questionDiv button{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
}
.questionInput{
    width: 90%;
}
.phraseIntro{
    font-size:x-large;
    font-style: italic;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto 0 auto;
}

.latest{
    padding-left: 0.5%;
    font-size: large;
    color: rgba(244, 243, 232);
}

.vignettes{
    background-color:rgba(214, 195, 178);
    margin: 10% 0 0 0;
    padding: 0 0 2% 0;
    min-width: 100%;
    height: auto;
}
.reviewsContainer
{
    margin: auto;
    width: 85%;
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: space-between;
    
}
.flip-card{
    margin: auto; 
 }
 .logoAccueil{
     margin-top: 2%;
     padding-bottom: 2%;
 }
 .logoImg{
    display: block;
    margin: auto;
     height: auto;
     width: 17%;
 }
@media only screen and (max-width: 2000px) {
    .vignettes
    {
        width: 1024px;
    }
}
@media only screen and (max-width: 1024px) {
    .vignettes
    {
        width: 768px;
    }
    .phraseIntro{
        font-size:larger;
    }
}
@media only screen and (max-width: 768px) {
    .vignettes
    {
        margin-top: 20%;
        width: 425px;
    }
    .phraseIntro{
        font-size:medium;
    }
}
@media only screen and (max-width: 425px) {
    .vignettes
    {
        width: 375px;
    }
    .reviewsContainer
    {
        grid-template-columns: 100%;
    }
    .flip-card{
        margin-top: 10%;
    }
    .phraseIntro{
        font-size:smaller;
        word-wrap: break-word;
        width: 100%;
        margin-top: 5%;
        padding: 0 12% 0 12%;
    }
    .logoAccueil{
        margin-top: 25%;
    }
    .logoImg{
        width: 40%;
     }
}
@media only screen and (max-width: 424px) {
    .vignettes
    {
        width: 320px;
    }
    .phraseIntro{
        padding: 0 15% 0 15%;
    }
}
@media only screen and (max-width: 375px) {
    .vignettes
    {
        width: 320px;
    }
    .phraseIntro{
        font-size:small;
        padding: 0 5% 0 10%;
    }
}
@media only screen and (max-width: 320px) {
    .vignettes
    {
        width: 80%;
    }
}

.aboutClass{
    margin: 2% 0 3% 5%;
    font-size: 50px;
    font-style: italic;
    cursor: default;
}
.textIntro{
    width: 50%;
    margin: 0 auto 0 auto;
    display: grid;
    grid-template-columns: 50% 50%;
}

.popcorn{
    min-width: 80%;
    max-width: 80%;
    height: auto;
}

.textIntro p{
    text-align: justify;
    cursor: default;
}

.textIntro p span{
    font-size: 15px; 
    font-style: normal; 
    -webkit-font-feature-settings: normal; 
            font-feature-settings: normal; 
    -webkit-font-variant-ligatures: normal; 
            font-variant-ligatures: normal; 
    font-variant-caps: normal; 
    font-weight: 400; 
    letter-spacing: normal; 
    orphans: 2; 
    text-align: left; 
    text-indent: 0px; 
    text-transform: none; 
    white-space: pre-wrap; 
    widows: 2; 
    word-spacing: 0px; 
    -webkit-text-stroke-width: 0px; 
    text-decoration-thickness: initial; 
    -webkit-text-decoration-style: solid; 
            text-decoration-style: solid; 
    -webkit-text-decoration-style: initial; 
            text-decoration-style: initial; 
    -webkit-text-decoration-color: inherited; 
            text-decoration-color: inherited; 
    -webkit-text-decoration-color: initial; 
            text-decoration-color: initial; 
    display: inline; 
    float: none;
    cursor: default;
}

.bioContainer{
    background-color:rgba(214, 195, 178);
    padding-bottom: 3%;
    margin: 0 auto 0 auto;
}
.bioTitre{
    margin: 2% 0 3% 5%;
    padding-top: 2%;
    font-size: 50px;
    font-style: italic;
    cursor: default;
}
.bio{
    width: 50%;
    margin: 0 auto 0 auto;
    display: grid;
    grid-template-columns: 50% 50%;
}
.bio p{
    text-align: justify;
    cursor: default;
}

.bio p span{
    font-size: 15px; 
    font-style: normal; 
    -webkit-font-feature-settings: normal; 
            font-feature-settings: normal; 
    -webkit-font-variant-ligatures: normal; 
            font-variant-ligatures: normal; 
    font-variant-caps: normal; 
    font-weight: 400; 
    letter-spacing: normal; 
    orphans: 2; 
    text-align: left; 
    text-indent: 0px; 
    text-transform: none; 
    white-space: pre-wrap; 
    widows: 2; 
    word-spacing: 0px; 
    -webkit-text-stroke-width: 0px; 
    text-decoration-thickness: initial; 
    -webkit-text-decoration-style: solid; 
            text-decoration-style: solid; 
    -webkit-text-decoration-style: initial; 
            text-decoration-style: initial; 
    -webkit-text-decoration-color: inherited; 
            text-decoration-color: inherited; 
    -webkit-text-decoration-color: initial; 
            text-decoration-color: initial; 
    display: inline; 
    float: none;
}
.bioImg{
    min-width: 80%;
    max-width: 80%;
    height: auto;
    margin-left: auto;
}

.thanks{
    margin: 2% auto 3% auto;
    min-height: 400px;
}
.thanksContainer{
    width: 50%;
    margin: 0 auto 0 auto;
    text-align: left;
    display: grid;
    grid-template-columns: 60%;
}
.thanks .thanksTxt{
    font-size: 20px;
    display: block;
    cursor: default;
}
.thanks .thanksSTxt{
    cursor: default;
}
.thanksTxtDiv{
    margin-bottom: 3%;
}
.thanks .thanksTitre{
    margin: 2% 0 3% 5%;
    font-size: 50px;
    font-style: italic;
    cursor: default;
}

@media only screen and (max-width: 1440px) {
    .textIntro{
        width: 70%;
    }
    .bio{
        width: 70%;
    }
    .thanksContainer{
        width: 70%;
    }
}
@media only screen and (max-width: 1024px) {
    .textIntro{
        width: 90%;
    }
    .bio{
        width: 90%;
    }
    .thanksContainer{
        width: 90%;
    }
}
@media only screen and (max-width: 768px) {
    .textIntro{
        width: 100%;
        grid-template-columns: 40% 60%;
    }
    .textIntro p span{
        font-size: small;
    }
    .popcorn{
        min-width: 90%;
        max-width: 90%;
    }
    .bio{
        width: 100%;
        grid-template-columns: 50% 50%;
    }
    .bioImg{
        min-width: 80%;
        max-width: 80%;
    }
    .bio p span{
        font-size: small;
    }
    .thanksContainer{
        width: 100%;
        grid-template-columns: 60%;
    }
    .thanks{
        font-size: small;
    }
}
@media only screen and (max-width: 425px) {
    .textIntro{
        width: 100%;
        grid-template-columns: 100%;
    }
    .textIntro p{
        margin-top: 5%;
    }
    .textIntro p span{
        font-size: small;
    }
    .popcorn{
        min-width: 100%;
        max-width: 100%;
    }
    .bioContainer{
        padding-bottom: 10%;
    }
    .bio{
        width: 100%;
        grid-template-columns: 100%;
    }
    .bioImg{
        min-width: 100%;
        max-width: 100%;
    }
    .thanksContainer{
        width: 100%;
        grid-template-columns: 100%;
    }
}
@media only screen and (max-width: 375px) {
    .thanks{
        min-height: 300px;
    }
    .thanks .thanksTitre{
        font-size: 40px;
        margin: 2% 0 3% 5%;
    }
    .thanks .thanksTxt{
        font-size: medium;
    }
    .bioTitre{
        font-size: 40px;
    }
    .aboutClass{
        font-size: 40px;
    }
}
@media only screen and (max-width: 320px) {
    .thanks .thanksTitre{
        font-size: 35px;
    }
    .thanks .thanksTxt{
        font-size: small;
    }
    .thanks .thanksSTxt{
        font-size: smaller;
    }
    .bioTitre{
        font-size: 35px;
    }
    .aboutClass{
        font-size: 35px;
    }
}

.privateReview {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 50%;
    margin: 5% auto 0 auto;
  }
  
.privateReview td, .privateReview th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
.privateReview tr:nth-child(even){background-color: #f2f2f2;}
  
.privateReview tr:hover {background-color: #ddd;}
  
.privateReview th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: white;
    color: black;
  }
.reviewRow td svg{
  cursor: pointer;
  margin-left:0px ;
}
.previousReviews{
    width: 10%;
    margin: 0 auto 0 25%;
}
.nextReviews{
    width: 10%;
    margin: 0 auto 0 25%;
}
.controlsDiv{
    width: 25%;
    margin: 0 auto 0 auto;
}

