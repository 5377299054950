.overlay {
    width: 50%;
    min-height: 500px;
    height: auto;
    border-radius: 5px;
    margin: 2% auto;
    box-shadow: 0 9px 50px hsla(20, 67%, 75%, 0.31);
    padding: 2%;
    background: white;
}
.logo{
    margin-top: 5%;
    text-align: center;
}

.logo img{
    height: auto;
    min-width: 20%;
    max-width: 20%;
    opacity: 0.8;
}
.title{
    font-size:300%;
    font-weight: bold;
    text-align: center;
    display: block;
}
.sousTitre{
    font-size:large;
    font-weight: bold;
    text-align: center;
    display: block;
    margin-bottom: 2%;
}
.realisateur{
    text-align: center;
    font-size:small;
}
.review{
    font-size:medium;
    margin: 5% 10% 0 10%;
    text-align: justify;
    cursor:default;
}
.datePub{
    width: 100%;
    margin: 2% 0 0 0;
    text-align: right;
}
.socialMediaButtons{
    width: 35%;
    margin: 2% auto 0 auto;
    display: grid;
    grid-template-columns: 50% 50%;
}
.facebookWrapper{
    width: 50%;
    text-align: center;
    display: grid;
    padding-left: 85%;
    grid-template-rows: 50% 50%;
}
    
.twitterWrapper{
    width: 50%;
    padding-right: 40%;
    text-align: center;
}
.questionsList{
    margin: 7% auto;
}
.questionsList .questionTitre{
    font-size:larger;
    font-weight: bold;
    text-align: center;
    display: block;
    margin-bottom: 5%;
}
.questionsList .questionDiv{
    width: 80%;
    font-size:medium;
    margin: auto;
    text-align: justify;
    cursor:default;
}
@media only screen and (max-width: 1440px) {
    .facebookWrapper{
        padding-left: 80%;
    }
}
@media only screen and (max-width: 1024px) {
    .overlay
    {
        width: 75%;
    }
    .socialMediaButtons{
        width: 50%;
    }
    .facebookWrapper{
        padding-left: 80%;
    }
        
    .twitterWrapper{
        padding-right: 40%;
    }
}
@media only screen and (max-width: 768px) {
    .overlay
    {
        width: 80%;
    }
    .datePub{
        font-size: medium;
    }
    .questionsList .questionTitre{
        font-size: large;
    }
    .socialMediaButtons{
        width: 70%;
    }
    .facebookWrapper{
        padding-left: 80%;
    }
        
    .twitterWrapper{
        padding-right: 40%;
    }
}
@media only screen and (max-width: 425px) {
    .overlay
    {
        margin-top: 25%;
        width: 95%;
    }
    .review{
        text-justify:inter-character;
        font-size: smaller;
    }
    .logo img{
        min-width: 30%;
        max-width: 30%;
    }
    .title{
        font-size:275%;
    }
    .realisateur{
        font-size:x-small;
    }
    .datePub{
        font-size: smaller;
    }
    .questionsList .questionTitre{
        margin-top:10%;
    }
    .questionsList .questionDiv{
        font-size: smaller;
    }
    .socialMediaButtons{
        width: 100%;
    }
    .facebookWrapper{
        padding-left: 80%;
    }
        
    .twitterWrapper{
        padding-right: 40%;
    }
}
@media only screen and (max-width: 375px) {
    .review{
        font-size: x-small;
    }
    .title{
        font-size:250%;
    }
    .datePub{
        font-size: x-small;
    }
    .questionsList .questionDiv{
        font-size: x-small;
    }
    .facebookWrapper{
        padding-left: 70%;
    }
        
    .twitterWrapper{
        padding-right: 35%;
    }
}