.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.navbar-brand{
  width: 30%;
}
.webName{
  height: auto;
  min-width: 50%;
  max-width: 50%;
}
.navbar{
  position:fixed;
  background-color:rgba(214, 195, 178);
  width: 100%;
  z-index: 5000;
}
header{
  padding-bottom: 100px;
}
.mediaNav
{
  min-width: 10%;
  max-width: 10%;
  margin-right: 3%;
  height: auto;
  float: left;
}
.navContainer
{
  min-width: 100%;
  margin: 0 0 0 0 ;
}

html {
  font-size: 10px;
}
@media (min-width: 769px) {
  html {
    font-size: 14px;
  }
}
@media (min-width: 1025px) {
  html {
    font-size: 16px;
  }
}
@media only screen and (min-width: 2560px) {
  html {
    font-size: 25px;
  }
}
@media (max-width:1024px){
  .webName{
    min-width: 60%;
    max-width: 60%;
  }
}
@media (max-width:768px){
  .webName{
    min-width: 80%;
    max-width: 80%;
  }
}
@media (max-width: 426px) {
  html {
    font-size: 16px;
  }
  .navbar-brand{
    width: 70%;
  }
  .webName{
    min-width: 70%;
    max-width: 70%;
    float: left;
  }
  .mediaContainer{
    width: 15%;
  }
  .mediaNav
  {
    margin-left: 5%;
    margin-right: 0;
    min-width: 40%;
    max-width: 40%;
  }
  .navContainer{
    display: grid;
  }
  .mr-2{
    width: 50%;
    height: auto;
    margin: 0 0 0 25%;
  }
}