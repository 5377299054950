@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
body{
  background-color:white;
  font-family: 'Playfair Display', serif;
}
.mainContainer{
  min-width: 100%;
  margin: 0 0 0 0;
}
.construction{
    width: 50%;
    margin: 15% auto 0 auto;
    text-align: center;
    display: grid;
    grid-template-columns: 100%;
}
.construction span{
  font-size: 40px;
  display: block;
  cursor: default;
}
@media only screen and (max-width: 1024px) {
  .construction{
    margin: 25% auto 0 auto;
  }
  .construction span{
    font-size: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .construction{
    margin: 40% auto 0 auto;
  }
  .construction span{
    font-size: 23px;
  }
}
@media only screen and (max-width: 425px) {
  .construction{
    margin: 60% auto 0 auto;
  }
  .construction span{
    font-size: 21px;
  }
}
@media only screen and (max-width: 375px) {
  .construction{
    margin: 70% auto 0 auto;
  }
  .construction span{
    font-size: 18px;
  }
}
@media only screen and (max-width: 320px) {
  .construction{
    margin: 90% auto 0 auto;
  }
  .construction span{
    font-size: 16px;
  }
}