.privateReview {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 50%;
    margin: 5% auto 0 auto;
  }
  
.privateReview td, .privateReview th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
.privateReview tr:nth-child(even){background-color: #f2f2f2;}
  
.privateReview tr:hover {background-color: #ddd;}
  
.privateReview th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: white;
    color: black;
  }
.reviewRow td svg{
  cursor: pointer;
  margin-left:0px ;
}
.previousReviews{
    width: 10%;
    margin: 0 auto 0 25%;
}
.nextReviews{
    width: 10%;
    margin: 0 auto 0 25%;
}
.controlsDiv{
    width: 25%;
    margin: 0 auto 0 auto;
}
